
.winnersContainer{
    align-items: center;
    text-align: center;
    margin: 3em;
}

.trackTitle{
    font-size: 2em;
}

.trackContainer{
    margin:2em;
    align-items: center;
}

.teamContainer{
    align-items: center;
    margin: 1em;
    max-width: 20em;
}

.teamDescription{
    font-size: 0.7em;
}

.teamTitle{
    font-size: 1em;
    font-family: 'Ultramagnetic','Helvetica';
}

.teamImage{
    height: 3em;
    width: auto;
    margin:1em;

}

.viewPitchButton{
    margin-top: 1em;
}

.viewAllPitchesButton{
    width: fit-content;
}

@media (max-width: 767px) {
    .winnersRow{
        flex-direction: column;
    }

}