.committeeInfoContainer{
    text-align: center;
}
.committeeInfoTitle{
    
    font-weight: bold;
    font-size: 1em;
}
.committeeInfoDescription{
    margin:0.5em;
    font-size: 0.7em;
    text-align: justify;
}

.peopleColumn{
    justify-content: center;
    align-content: center;
    text-align: center;
}

.peopleRow{
    justify-content: center;
    align-content: center;
}

.profileContainer{
    margin: 1em;
    color:beige;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.trackLeadsContainer{
    margin: 3em;
}
.profileImage{
    margin: 1em;
    width: 7em;
    height: auto;
    border-radius: 10em;
}

.nameText{
    font-size: 0.75em;
    font-weight: bold;
    
}

.titleText{
    font-size:0.70em;
}

.socialIconRow{
    justify-content: space-between;
    align-items: center;
}

.socialIcon{
    cursor: pointer;
    margin: 0.25em;
}

@media (max-width: 767px) {

    .peopleRow{
        flex-direction: column;
    }
}