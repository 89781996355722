/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
/* https://db.onlinewebfonts.com/c/a0c5a73e2d9f77d5b99cc2cfbf0d7ba9?family=Ultramagnetic */
@font-face {
  font-family: 'Ultramagnetic';
  src: local('Ultramagnetic'), url(./fonts/Ultramagnetic.ttf) format('truetype');
}

html{
  width:100%;
  height: 100%;
}
body {
  margin: 0;
  font-family:  'Helvetica', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height: 100%;
  font-size: 1.5em;

}
#root{
  width:100%;
  height: 100%;
}


.flexRow{
  display: flex;
  flex-direction: row;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}


/* Ideathon Page */
.container{
  background-color:black;
  padding: 2em;
  color: white;
  font-family:Monaco;
  height: fit-content;
  width: 100%;
}

.messengerIcon{
  width:auto;
  height: 2em;
  cursor: pointer;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.messengerIconContainer{
  z-index: 1;
  height: 3em;
  max-width: 15em;
  display: flex;
  margin-top: 1em;
  border-radius: 2em;
  align-items: center;
  justify-content: center;
  color: blueviolet;
  background-color: rgb(255, 240, 240);
}

.messengerIconContainer:hover{
  opacity: 50%;
}

.socialIcon{
  cursor: pointer;
}
.socialIcon:hover{
  opacity: 50%;
}

.socialIconsContainer{
  justify-content: space-evenly;
  width: 15em;
  align-items: center;

}
.socialSection{
  margin-top: 2em;
  align-items: center;
}

.copyrightContainer{
  flex-direction: row;
  align-items: center;
  margin:2em;
  font-size: 0.7em;
}
.ideathonLogo{
  width:5em;
  height: auto;
  margin: 1em;
}

.typingTitle{
  font-size: 80px;
  font-weight: bold;
  font-family: "Ultramagnetic","Helvetica Neue", sans-serif;

}

.threeDEffectBlack{
  color: white ;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-shadow: -0.02em 0 0 #F22613,  0.02em 0 0 #00FFFF;
}

.typingAnimationFirst { 
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
}

.typingAnimationFirst {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

.typingAnimationSecond {
  animation-delay: 2s;

  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
             
}
.sponsorsSection{
  align-items:center;
  display:flex;
  flex-direction:column;
  justify-content:center;

  margin-top: 4em;
  background-color: white;
  border-radius: 2em;
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  box-shadow: 1px 2px 3px rgba(0,0,0,.5);  
}

.dotsContainer{
  width:5em;
  height:53m;
  justify-content:space-evenly;
  align-items:space-evenly;
  vertical-align:center;
}

.whenAndWhereContainer{
  justify-content:center;
  margin-top:3em;
}
.whenAndWhereText{
  font-size: 1em;
  vertical-align:center ; 
}

.eventShortDescription{
  font-size: 1em;
}

.trackDescriptions{
  text-align: center;
  justify-content: space-around;
  padding-top:20px;
  font-weight:bold;
  font-size:1em;
  text-align:center
}

.trackTitle{
  font-size: 1em;
  cursor: pointer;
}
.trackTitle:hover{
  color:chartreuse;
}

.actionButton{
  margin-bottom: 10px;
  height:2em;
  font-size:0.7em;
  width:15em;
  cursor:pointer;
}

#sponsorRow{
  display: flex;
  flex-direction: row;
}

.sponsorLogo{
  display:block;
  max-width:5em;
  height:auto;
  margin:1em;
  cursor: pointer;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .chevronToRight{
    width:1em;
    transform: scale(0.1);
  }
  #sponsorRow{
    flex-direction: column;
  }

}


/* text animation */

@keyframes animated-text{
  from{width: 0%;}
  to{width: 100%;}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}


/* Appear animation */

.fadeInSlow{
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 

}

.fadeInFast{
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 

}

@keyframes fadeInAnimation {
  0% {opacity:0;}
  66% { opacity:0; }
  100% {opacity: 1;}
} 


.coolGradient{
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);  
}

.topicName{
  color:yellow;
  /* animation: topicChangingColor ease 10s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;  */
}

@keyframes topicChangingColor{
  from{color:red;}
  to{color:yellow;}
}

.topicName:hover{
  color:rgb(0, 123, 255);
  transform: scale(1.5);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 767px) {

  .showMoreButton{
    display: flex;
  }

  .btn{
    width:100%;
  }

  .eventShortDescription{
    font-size: 1.12em;
  }
  .whenAndWhereContainer{
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }
  .dotsContainer{
    width: 2em;
    height: 2em;
  }
  .trackTitle{
    font-size: 0.5em;
  }
  .trackDescriptions{
    flex-direction: column;
  }

  .whenAndWhereText{
    font-size: 1.0em;
  }
  .listOfTopics{
    flex-direction: column;
    text-align: center;
  }

  .typingTitle{
    font-size: 30px;
  }  


}
