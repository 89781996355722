
/* Navbar */
.navbar{
    position: absolute;
    top:1em;
    right:1em;
    justify-content: space-evenly; 
    z-index: 2;
  }

  .logoTitle{
    display: none;
    font-family: 'Ultramagnetic','Helvetica';
}  
  
  .navBarItemsContainer{
    display: flex;
    flex-direction: row;
    width: 30em;  
    justify-content: space-evenly;
  }

  .glowingText{
    animation: glowingAnimation 4s infinite;
  }

  @keyframes glowingAnimation{ 
    0%{ 
        color: azure; 
    } 
    30%{ 
        color: gold; 
        } 

    60%{ 
        color: goldenrod; 
        }         
    90%{ 
      color:lemonchiffon;
    } 
} 

  
  .navbarItem{
    font-size: 0.8em;
    align-items: center;
    cursor: pointer;
  }
  .navbarItem:hover{
    color:blue;
  }

  /* .localeSelection{
    position: absolute;
    top:1em;
    right:1em;
  } */

  .localesContainer{
    width: 3.5em;
    justify-content: space-around;
  }
  
  .localeButton{
    cursor: pointer;
  }
  .localeButton:hover{
    color:tomato;
  }

  .showMoreButton{
    display: none;

  }

  

  @media (max-width: 767px) {
    .navbar{
        align-content: center;
        justify-content: center;
        position: absolute;
        top:0em;
        right:0em;
        width: 100%;
        background-color: blue;        
    }

    .logoTitle{
        display: flex;
        position: absolute;
        top:0.8em;
        left:2em;
    }    
    .engagedNavbar{
      padding: 0.5em;
      min-height: 2em;
    }
    .disEngagedNavbar{
        height: 2.6em;
    }
    .navBarItemsContainer{
        width: 100%;
        align-items: center;
    }
    .navbarItem{
    font-size: 1.1em;
    margin: 0.2em;
    }

    .showMoreButton{
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 0.7em;
    right: 0.7em;
    }      

    .engagedNavItemsContainer{
        display: flex;
        flex-direction: column;
        margin-top: 3em;
    }

    .disEngagedNavItemsContainer{
        display: none;
    }
    .localesContainer{
        width:5em;
    }

}    