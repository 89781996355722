
/* Schedule Page */
.scheduleTitle{
    font-family: 'Ultramagnetic';
    font-size: 1.25em; 
  }
  
  .scheduleContainer{
    font-size: 0.75em;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .dateColumn{
    text-align: center;
    width: 30em;
  }
  
  .currentDateColumn{
    color:yellow;
    font-weight: bold;
    font-size: 1.2em;


  }
  
  .dayTitle{
    font-size: 1em;
    font-weight: bold;
  }
  .dateTitle{
    border-bottom: 1em;
    margin-bottom: 1em;
    font-weight: bold;
  }
  
  .timeAndEvent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1em;
    margin: 0.5em;
    text-align: left;
  }

  .eventDiv{
      text-align: right;
  }

  @media (max-width: 767px) {
    .scheduleContainer{
        flex-direction: column;
    }
    .dateColumn{
        width: 100%;
        margin-top: 2em;
    }

  }