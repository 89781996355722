
/* FAQ Page */
.FAQtitle{
    font-family: 'Ultramagnetic';
    font-size: 1.25em;
  }
  
  .QAcontainer{
    margin:0.5em;
  }
  
  .questionContainer{
    font-size: 1em;
    font-weight: bold;
    color: yellow;
  
  }
  
  .answerContainer{
    font-size: 0.9em;
  }
  .termsBody{
      font-style: italic;
  }